<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">KYC</h3>
        </b-col>
        <!-- <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new Customer" placement="top">
            <base-button type="primary" icon size="sm" @click="addCustomer()">
              <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col> -->
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="p-2">
        <base-input v-model="searchText"
          v-on:input="debouncedHandler"
                    prepend-icon="fas fa-search"
                    placeholder="Search...(case-sensitive)">
        </base-input>
      </div>
    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              v-loading="loading"
              row-key="id">
      <el-table-column label="id"
                       min-width="60px"
                       prop="customer_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.customer_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Customer Information"
                       prop="customer_name"
                       min-width="180px"
                       sortable>
        <template v-slot="{row}">
          <div>
            <b>Name: {{row.customer_name}}</b>
          </div>
          <div>
            <b>I/C: {{row.customer_ic}}</b>
          </div>
          <div>
            <b>Email: {{row.customer_email}}</b>
          </div>
          <div>
            <b>Contact: {{row.customer_contact}}</b>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="IC"
                       prop="customer_ic"
                       min-width="200px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.customer_ic}}</b>
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="Email & Contact"
                       prop="customer_email"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}"> 
          <b>Email: {{row.customer_email}}</b>
          <div>
            <b>Contact: {{row.customer_contact}}</b>
          </div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column label="Contact"
                       prop="customer_contact"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.customer_contact}}</b>
        </template>
      </el-table-column> -->

      <el-table-column label="Form Received"
                       prop="customer_forms"
                       min-width="300px"
                       sortable>
        <template v-slot="{row}">
          <div v-for="(customer_form, index) in row.customer_forms" :key="customer_form.customer_form_id">
            <b-row>
              <div>
                <b>{{index+1}}) {{customer_form.customer_form_name}}</b>
                <b-col>
                  Question Answered: {{customer_form.total_customer_answered_questions}}/{{customer_form.total_customer_questions}}&nbsp;&nbsp;
                  <i v-if="customer_form.total_customer_answered_questions == customer_form.total_customer_questions" class="fas fa-check"></i>
                  <i v-else-if="customer_form.total_customer_answered_questions > 0" class="fas fa-hourglass-half"></i>                  
                  <i v-else class="fas fa-times"></i>
                </b-col>
                <b-col> - <a :href="`/kyc_forms/${row.customer_id}/${customer_form.customer_form_id}/${row.link_customer_name}`" target="_blank" ref="mylink">Customer Answer</a></b-col>
                <b-col> - <a :href="`/kyc_forms_answer/answer/${row.customer_id}/${customer_form.customer_form_id}`" target="_blank">Advisory Comment</a></b-col>
                <b-col><b>Date Created: {{customer_form.created_at}}</b></b-col>
                <b-col><strong>OTP: {{customer_form.otp}} (expired at: {{customer_form.otp_expired}})</strong></b-col>
              </div>
              <div class="btn-group-vertical pl-4">
                <base-button type="primary" size="sm" @click="copyLink(`/kyc_forms/${row.customer_id}/${customer_form.customer_form_id}/${row.link_customer_name}`)">Copy Link</base-button>
                <base-button type="primary" size="sm" @click="copyOTP(customer_form.otp)">Copy OTP</base-button>
                <base-button type="primary" size="sm" @click="generateOTP(row,customer_form.customer_form_id)">Resend OTP</base-button>
              </div>
            </b-row>
            <br>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="External Customer Id"
                       prop="external_customer_id"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.external_customer_id}}</b>
        </template>
      </el-table-column> -->

      <el-table-column label="From"
                       prop="external_application_name"
                       min-width="80px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.external_application.external_application_name}}</b>
        </template>
      </el-table-column>

      <!-- <el-table-column label="External Customer Id"
                       prop="external_customer_id"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.external_customer_id}}</b>
        </template>
      </el-table-column> -->

      <el-table-column label="Created By"
                       min-width="100px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <el-table-column min-width="80px" align="center">
        <div slot-scope="{row}" class="table-actions btn-group-vertical">
          <base-button type="primary" icon size="sm" @click.prevent="onEdit(row)">
            <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
            <span class="btn-inner--text">Edit</span>
          </base-button>
          <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
            <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
            <span class="btn-inner--text">Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>

  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader, homeLink} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'customer-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['customer_name', 'customer_email', 'external_customer_id'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: '',
        debouncedHandler: '',
        loading: false,
        TableColumn:[{
          type: 'selection'
        }]
      };
    },
    created(){
    },
    mounted(){
      this.customerList(),
      this.debouncedHandler = debounce(() => {
        this.searchCustomerList();
      }, 1000);
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows
      },
      copyLink(link) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(window.location.origin+link);
        }
        else
        {
          this.unsecureCopyToClipboard(window.location.origin+link);
        }
        // navigator.clipboard.writeText(window.location.origin+link);
        this.$notify({
          message:
            '<b>KYC Link copied to clipboard',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      copyOTP(otp) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(otp);
        }
        else
        {
          this.unsecureCopyToClipboard(otp);
        }
        // navigator.clipboard.writeText(otp);
        this.$notify({
          message:
            '<b>OTP copied to clipboard.',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      unsecureCopyToClipboard(link)
      {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      },
      generateOTP(customer, customer_form_id) {
        const postData = {
          customer_form_id: customer_form_id,
          customer_id: customer.customer_id,
        }

        axios.put(apiDomain + 'customers/customer_form.generate_new_OTP', postData, { headers: getHeader() })
        .then(response => {
          this.sendEmail(customer, customer_form_id)
          this.$notify({
            message:
              '<b>New OTP : ' + response.data.data[0].otp + '</b> - Successfully Generated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.customerList();
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Customer") 
        });
      },
      sendEmail(customer, customer_form_id){
        const emailData = {
          customer_name: customer.customer_name,
          customer_email: customer.customer_email,
          customer_form_id: customer_form_id,
          kyc_form_link: homeLink + '/kyc_forms/' + customer.customer_id + '/' + customer_form_id + '/' + customer.link_customer_name,
        }

        axios.post(apiDomain + 'customers/customer_form.send_email', emailData, { headers: getHeader() })
        .then(response => {
          if(response.status === 200)
          {                  
            this.$notify({
              message:
                '<b>Customer Form To ' + customer.customer_email + '</b> - Successfully Sent.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Customer Form Error Sending Email")
        });
      },
      onEdit(row) {
        this.$router.push({path: '/customer/editCustomers/' + row.customer_id })
        // alert(`You want to edit ${row.name}`)
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'customers/customer/' + row.customer_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Customer : ' + row.customer_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.customerList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Customer")
                
            });
          } 
        })
      },
      addCustomer(){
        this.$router.push({path: '/customer/addCustomers' })
      },
      async customerList(){
        this.loading = true
        if(this.$route.params.customer_id)
        {
          await axios.get(apiDomain + 'customers/customer.get_customer/' + this.$route.params.customer_id, { headers: getHeader() })
          .then(response => {
            this.tableData = response.data.data
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Delete Customer") 
          });
        }
        else
        {
          await axios.get(apiDomain + 'customers/customer', { headers: getHeader() })
          .then(response => {
            this.tableData = response.data.data
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Delete Customer") 
        });
        }
        
        const str = JSON.stringify(this.tableData, null, 2);
        this.loading = false
      },
      async searchCustomerList()
      {
        this.loading = true
        const postData = {
          controller_name : "CustomerController",
          search_text : this.searchText,
          table_column: ["customer_name", "customer_email","external_customer_id"]
        }

        await axios.post(apiDomain + 'customers/data_search', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>Customer : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            this.$notify({
              message:
                '<b>Customer : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Customer Listing")
        });
        this.loading = false
        this.total = this.tableData.length
        this.pagination.currentPage = 1
      }

    }
  }
</script>
<style scoped>
.el-table .cell {
  word-break: break-word;
}
.el-table__row {
  border-width: 5px;
  border-style: solid;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
.btn-group-vertical > .btn:not(:first-child){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
.fa-check {
  color: lightgreen;
}
.fa-times {
  color: red;
}
</style>

